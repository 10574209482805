/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Title } from '@mantine/core';
import { SignInForm } from '@medplum/react';
import { useNavigate } from 'react-router-dom';
import { useBrandSettings } from '../BrandContext';
import SignInCarousel from './SignInCarousel';

export function SignInPage(): JSX.Element {
  const navigate = useNavigate();
  const brandDetails = useBrandSettings();

  return (
    <div className="tw-flex tw-min-h-screen tw-sm:block">
    <div className='tw-flex tw-flex-col tw-justify-center tw-w-1/2 sm:tw-w-[100%] banner-bg-2 tw-block tw-m-[auto]'>
      <div className='tw-flex'>
        <div className='tw-w-[23%] tw-mb-4'>
         
        </div>
        <img src={brandDetails?.brandLogo || '../img/Pragma_logo_Purple.svg'}
            onError={(e) => { 
              const target = e.target as HTMLImageElement;
              target.onerror = null; 
              target.src = '../img/Pragma_logo_Purple.svg';
            }} 
          className='tw-w-[180px] tw-block' alt='pragmaconnect Logo' />
      </div>
      <SignInForm
        // Configure according to your settings
        googleClientId=""
        onForgotPassword={() => navigate('/resetpassword')}
        onSuccess={() => navigate('/dashboard')}
      >
        <Title style={{fontWeight: '600', fontSize: '25px'}}>Login In</Title>
      </SignInForm>
    </div>
      <div className="tw-w-1/2 tw-p-4">
        <SignInCarousel />
      </div>
    </div>
  );
}
