/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import GroupButtons from '../../components/GroupButtons';
import AssessmentInsights from './AssessmentInsights';
import ProgressReport from './ProgressReport';
import { useBrandSettings } from '../../BrandContext';

const AIInsights = () => {
  const brandDetails = useBrandSettings();
  const [activeTab, setActiveTab] = useState<string>('');
  const buttonName: any = [
    { id: 1, name: 'Assessment Insights'},
    { id: 2, name: 'Progress Reports'}
  ]

  const buttonHandleClick = (data: {id: number, name: string}) => {
    setActiveTab(data.name);
  }

  return (
    <>
      <div className='tw-mb-6'>
        <div className='tw-bg-[#F9F5FF] tw-p-2 tw-rounded-t-lg tw-py-3 tw-flex tw-justify-between tw-items-center tw-opacity-[1]' style={{ backgroundColor: brandDetails?.secondaryColor}}>
          <div>
            <h5 className="tw-text-[18px] xs:tw-text-[18px] tw-font-semibold tw-tracking-tight tw-text-[#101828]">AI Insights</h5>
            <h6 className='tw-text-[#475467] tw-text-[14px]'>Insights on patient behavior to improve engagement and satisfaction</h6>
          </div>
          <div>
            <GroupButtons value={buttonName} buttonHandleClick={(data)  => buttonHandleClick(data)}/>
          </div>
        </div>
        {activeTab === 'Assessment Insights' && (
          <AssessmentInsights />
        )}
        {activeTab === 'Progress Reports' && (
          <ProgressReport />
        )}
      </div>
    </>
  )
}

export default AIInsights
