/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
import { formatDate } from '@medplum/core';
import { IconEye, IconFileDownload} from '@tabler/icons-react';
import { Center, Container, Text } from '@mantine/core';
import { useState } from 'react';
import { getPage, getTotalPages } from '../../utils/util';
import { showNotification } from '@mantine/notifications';
import { useMedplum } from '@medplum/react-hooks';
import { exportClinicalNote } from '../../utils/CustomAPI';

interface MedicalHistoryProps {
  activeTab: number;
  resourceData: any;
  isLoading: boolean;
  offsetValue: (offset: number) => void;
  responseData: any;
}

interface RowData {
  [key: string]: string;
}

export function MedicalHistory({
  activeTab,
  resourceData,
  isLoading,
  offsetValue,
  responseData,
}: MedicalHistoryProps): JSX.Element {
  const medplum = useMedplum();
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState<number>(0);
  // Define column configurations for each tab
  const tabColumns: Record<number, string[]> = {
    1: ['Type', 'Category', 'Status', 'Date & Time', 'Practitioner'],
    2: ['Procedure Name', 'Visit Type', '	Start Date', 'End Date', 'Diagnosis', 'Clinical Status'],
    3: ['Condition Name', 'Visit Type', 'Recorded Date', 'Onset Start Date', 'Onset End Date', 'Severity'],
    4: ['Allergy Name', 'Type', 'Criticality', 'Onset Date', 'Reaction', 'Clinical Status'],
    5: ['Medication Name', 'Type', 'Start Date', 'No. of Repeats', 'Status'],
    6: ['Assessment Name', 'Completion Date', 'Status'],
  };

  const columns = tabColumns[activeTab] || [];

  const getResourceData = (data: any): RowData => {

    switch (activeTab) {
      case 1: // Clinical Notes
        return {
          'Type': data?.resource?.type?.coding?.find((item: { system: string; }) => item.system === 'http://loinc.org')?.display || '-',
          'Category': data?.resource?.category?.[0]?.coding?.[0]?.display || '-',
          'Status': data?.resource?.docStatus,
          'Date & Time': formatDate(data?.resource?.date) || '-',
          'Practitioner': data?.resource?.author?.[0]?.display || '-',
        };
      case 2: // Procedure
        return {
          'Procedure Name': data?.resource?.code?.coding ? data?.resource?.code?.coding[0].display : '-',
          'Recorded Date': data?.resource?.recordedDate || '-',
          'Start Date': formatDate(data?.resource?.performedPeriod?.start) || '-',
          'End Date': formatDate(data?.resource?.performedPeriod?.end) || '-',
          visit: data?.resource?.location?.display || '-',
          status: data?.resource?.status,
          dignosis: data?.resource?.reasonCode ? data?.resource?.reasonCode[0].text : '-',
        };
      case 3: // Conditions
        return {
          'Condition Name': data?.resource?.code?.text || '-',
          severity: data?.resource?.severity?.text || '-',
          'Recorded Date': formatDate(data?.resource?.recordedDate) || '-',
          'Start Date': formatDate(data?.resource?.onsetPeriod?.start) || '-',
          'End Date': formatDate(data?.resource?.onsetPeriod?.end) || '-',
          visit: data?.resource?.encounter?.display || '-',
          status: data?.resource?.status,
        };
      case 4: // Allergies
        return {
          'Allergy Name': data?.resource?.code?.text || '-',
          category: data?.resource?.category ? data?.resource?.category[0] : '-',
          severity: data?.resource?.severity || '-',
          reaction: data?.resource?.reaction ? data?.resource?.reaction[0].description : '-',
          'Onset Date': formatDate(data?.resource?.onsetPeriod?.start) || '-',
          visit: data?.resource?.location?.display || '-',
          status: data?.resource?.clinicalStatus?.coding[0]?.display,
        };
      case 5: // Medications
        return {
          'Medication Name': data?.resource?.medicationReference
          ? data?.resource?.medicationReference?.display
          : data?.resource?.medicationCodeableConcept
            ? data?.resource?.medicationCodeableConcept?.coding[0]?.display
            : '-',
          Type: data?.resource?.category ? data?.resource?.category[0]?.coding[0]?.display : '-',
          'No. of Repeats': data?.resource?.dispenseRequest?.numberOfRepeatsAllowed || '0',
          'Start Date': formatDate(data?.resource?.dispenseRequest?.validityPeriod?.start) || '-',
          Status: data?.resource?.status,
        };
      case 6: // Assessment
        return {
          'Assessment Name': data?.questionnaire?.name || '-',
          'Completion Date': formatDate(data?.response?.authored) || '-',
          Status: data?.response?.status,
        };
      default:
        return {} as Record<string, string>;
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setOffset((page - 1) * 10);
    offsetValue((page - 1) * 10);
  };

  const downloadPdf = (id: any, patientName: any) => {
    exportClinicalNote(medplum, id)
      .then((response: any) => {
        if (response.data) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${patientName}.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
          showNotification({ color: 'green', message: 'Exported successfully' });
        }
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        showNotification({ color: 'red', message: 'Failed to export' });
      });
  };

  return (
    <div className="tw-w-full tw-mt-3 tw-overflow-auto">
      {/* Table */}
      <div className="tw-bg-white ">
        <table className="tw-w-full tw-text-sm tw-text-left">
          <thead className="tw-text-xs tw-text-gray-700 tw-bg-gray-50 tw-border-b-[1px] tw-border-[#EAECF0]">
            <tr className="tw-py-3 tw-px-6">
              {columns.map((column, index) => (
                <th key={index} className="tw-py-3 tw-px-6 tw-text-[#475467] tw-font-medium tw-text-sm">
                  <div className="tw-flex tw-justify-between tw-items-center tw-w-max ">{column}</div>
                </th>
              ))}
              <th className="tw-py-3 tw-px-6 tw-text-[#475467] tw-font-medium "></th>
            </tr>
          </thead>

          <tbody>
            {!isLoading ? (resourceData?.map((resource: any, index: number) => {
              const rowData = getResourceData(resource);
              return (
                <tr key={index} className="tw-border-b hover:tw-bg-gray-50 tw-cursor-pointer">
                  {columns.map((column, colIndex) => (
                    <td key={colIndex} className="tw-px-6 tw-py-4 tw-font-normal tw-text-[#475467]">
                      {column === 'Status' && resource.status ? (
                        <div>
                          <span
                            className={`tw-border tw-rounded-full tw-py-[4px] tw-px-3 tw-font-medium ${
                              resource.status === 'not-done'
                                ? 'tw-border-[#FFC080] tw-text-[#8B4513] tw-bg-[#F7D2C4]'
                                : 'tw-border-[#ABEFC6] tw-text-[#067647] tw-bg-[#ECFDF3]'
                            }`}
                          >
                          {resource.status ? resource.status : rowData[column]}
                          </span>
                        </div>
                      ) : (
                        rowData[column] || null
                      )}
                    </td>
                  ))}
                  {activeTab === 1 ? (
                    <td className="tw-px-6 tw-py-4">
                      <IconFileDownload
                        size={22}
                        stroke={'1.67px'}
                        className="tw-text-[#475467] hover:tw-text-red-500 tw-cursor-pointer"
                        onClick={() => downloadPdf(resource?.resource?.id, resource?.resource?.subject?.display)}
                      />
                    </td>
                  ) : (
                    <td className="tw-px-6 tw-py-4">
                      <IconEye
                        size={22}
                        stroke={'1.67px'}
                        className="tw-text-[#475467] hover:tw-text-red-500 tw-cursor-pointer"
                      />
                    </td>
                  )}
                </tr>
              );
            })) : (
              <tr>
                <td colSpan={columns.length + 1}>
                  <Container>
                    <Center style={{ height: 150 }}>
                      <Text size="xl" c="dimmed">
                        Loading...
                      </Text>
                    </Center>
                  </Container>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {resourceData?.length === 0 && !isLoading && (
          <Container>
            <Center style={{ height: 150 }}>
              <Text size="xl" c="dimmed">
                No results
              </Text>
            </Center>
          </Container>
        )}
        {resourceData?.length !== 0 && (
          <div className="tw-flex tw-justify-between tw-items-center tw-mt-4 tw-px-5 tw-pb-5">
            <button
              className="tw-px-4 tw-py-2 tw-border tw-rounded-md tw-bg-white"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              &larr; Previous
            </button>
            <div className="tw-flex tw-items-center">
              {[...Array(Math.min(10, getTotalPages(offset, responseData)))].map((_, index) => {
                const pageNumber = index + 1;
                return (
                <button
                  key={index}
                  className={`tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-rounded-lg ${
                    index + 1 === getPage(offset)
                      ? 'tw-bg-[#F9FAFB] tw-text-gray-700'
                      : 'tw-text-gray-700 tw-bg-white'
                  }`}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {index + 1}
                </button>
                )
              })}
            </div>
            <button
              className="tw-px-4 tw-py-2 tw-border tw-rounded-md tw-bg-white"
              disabled={currentPage === Math.ceil(responseData?.total || 0 / 10)}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next &rarr;
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
