/* eslint-disable @typescript-eslint/explicit-function-return-type */
import ActivitySummary from './ActivitySummary';
import HeartRate from './HeartRate';
import BloodPressure from './BloodPressure';
import Weight from './Weight';

const FeaturedCards = () => {
  return (
    <>
      <div className="tw-flex tw-gap-4 xs:tw-gap-0 tw-flex-nowrap lg:tw-flex-nowrap sm:tw-flex-wrap xs:tw-flex-wrap">
        <ActivitySummary/>
        <HeartRate />
      </div>
      <div className="tw-flex tw-gap-4 xs:tw-gap-0 tw-flex-nowrap lg:tw-flex-nowrap sm:tw-flex-wrap xs:tw-flex-wrap">
        <BloodPressure/>
        <Weight />
      </div>
    </>
  );
};

export default FeaturedCards;
